import { render, staticRenderFns } from "./Tracking.vue?vue&type=template&id=68d6a992&scoped=true&"
import script from "./Tracking.vue?vue&type=script&lang=js&"
export * from "./Tracking.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d6a992",
  null
  
)

export default component.exports