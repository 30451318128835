<template>
  <div>
    <b-button
      native-type="button"
      type="is-success"
      @click="isModalActive = true;"
    >
      {{ driver != null ? 'Reassign Driver' : 'Assign Driver' }}
    </b-button>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ driver != null ? 'Reassign Driver' : 'Assign Driver' }}
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field label="Select a driver">
            <b-select
              v-model="form.driver_selected"
              placeholder="Select a driver"
              expanded
            >
              <option
                value=""
                selected
              >
                Select a driver
              </option>
              <option
                v-for="option in drivers"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'AssignDriver',
  props: {
    driver: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      form: {
        driver_selected: ''
      },
      drivers: [],
      isModalActive: false,
      errors: {},
      isLoading: false,
      id: this.$route.params.id
    }
  },
  watch: {
    driver: {
      handler () {
        this.form.driver_selected = this.driver.id
      },
      immediate: true
    }
  },
  created () {
    this.fetchDrivers()
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.post('/v1/super-admin/assign-driver', {
        schedule_id: this.id,
        driver_id: this.form.driver_selected
      })
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.form.driver_selected = ''
          this.$buefy.snackbar.open({
            message: 'Driver assigned successfully',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    fetchDrivers () {
      this.axios.get('/v1/super-admin/users?role=driver').then(response => {
        this.drivers = response.data.users
      })
    }
  }
}
</script>

<style scoped>

</style>
