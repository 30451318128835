<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-success"
        expanded
        @click="isModalActive = true"
      >
        Register Payment
      </b-button>
    </div>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Add Payment
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            label="Payment Option"
            horizontal
          >
            <b-select
              v-model="form.payment_option"
              placeholder="Select an option"
              expanded
            >
              <option
                v-for="item in items"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="form.payment_option !== 'Not Paid'"
            horizontal
            label="Amount (USD)"
            :type="{ 'is-danger': errors.amount }"
            :message="{ [errors.amount ? errors.amount[0] : '']: errors.amount }"
          >
            <b-input
              v-model="form.amount"
              name="amount"
              type="number"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'Payment',
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      errors: {},
      form: {
        payment_option: '',
        amount: 0.0,
        tracking_number: this.$route.params.id
      },
      items: [
        'Cash',
        'CashApp: $LincolnNdegwa(816-294-7973)',
        'Zelle: 816-294-7973',
        'Cheque: LincTech LLC',
        'Credit Card',
        'Not Paid'
      ]
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.post('/v1/app/payment', this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.$buefy.snackbar.open({
            message: 'Payment added successfully',
            queue: false
          })
          this.$emit('refresh')
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    }
  }
}
</script>

<style scoped>

</style>
