<template>
  <div>
    <b-button
      v-if="status"
      size="is-small"
      type="is-success"
      icon-right="pencil"
      @click="isModalActive = true"
    />
    <b-button
      v-else
      native-type="button"
      type="is-success is-small"
      @click="isModalActive = true; isEdit = false"
    >
      New Status
    </b-button>
    <hr>
    <b-notification
      v-for="statusUpdate in statusList"
      :key="statusUpdate.id"
      type="is-success is-light"
      icon="information-outline"
      size="24"
      has-icon
      role="alert"
      :closable="false"
    >
      <p>{{ statusUpdate.title }} {{ statusUpdate.custom_status != null ? '- ' + statusUpdate.custom_status : '' }}</p>
      <small style="color: #2e323a">{{ statusUpdate.created_at | formatDate(false) }}</small>
      <small
        class="is-clickable"
        style="float: right; color: blue"
        @click="edit(statusUpdate)"
      >[Click to edit]</small>
    </b-notification>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <span v-if="isEdit">Update tracking status</span>
            <span v-else>New tracking status</span>
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            label="Shipment status"
            horizontal
          >
            <b-select
              v-model="form.title"
              placeholder="Select an option"
              expanded
            >
              <option
                value=""
                selected
              >
                Select an option
              </option>
              <option
                v-for="item in items"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="form.title === 'Custom'"
            label="Custom status"
            message="Custom status"
            horizontal
          >
            <b-input
              v-model="form.custom_status"
              type="textarea"
              placeholder="Enter custom status"
              maxlength="255"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TrackingStatusUpdates',
  props: {
    status: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      items: [
        'Shipment pick up & Sail prep',
        'Loading items into the container',
        'Container loaded and sealed',
        'Container Picked up en-route to port facility',
        'Container en-route to Port facility',
        'Container at departing port facility',
        'Container cleared US customs for sailing',
        'Container loaded to vessel',
        'Vessel departs from Port',
        'Vessel in transit',
        'Vessel arrives Connecting Port',
        'Container Discharged for transhipment',
        'Container loaded into the final vessel',
        'Vessel en-route to Mombasa',
        'Vessel arrives Destination Port',
        'Vessel arrives Mombasa',
        'Vessel awaits birth allocation',
        'Vessel docked, awaits container discharge',
        'Container discharged, ready for customs clearing',
        'Destination customs clearing in progress',
        'Container cleared customs',
        'Container on-board truck to Nairobi',
        'Container in Nairobi',
        'Container off loading in progress',
        'Items available for pick-up from our warehouse',
        'Custom'
      ],
      form: {
        title: '',
        id: '',
        custom_status: ''
      },
      isModalActive: false,
      isEdit: false,
      errors: {},
      isLoading: false,
      statusList: []
    }
  },
  watch: {
    status: {
      handler () {
        if (this.status) {
          this.form.title = this.status.title
          this.isEdit = true
        }
      },
      deep: true
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    update () {
      this.isLoading = true
      this.axios.patch(`/v1/super-admin/container/status/edit/${this.form.id}`, this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.isEdit = false
          this.fetch()
          this.$buefy.snackbar.open({
            message: 'Status updated successfully',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    edit (status) {
      this.isEdit = true
      this.form.title = status.title
      this.form.custom_status = status.custom_status
      this.form.id = status.id
      this.isModalActive = true
    },
    submit () {
      if (this.isEdit) {
        this.update()
        return
      }
      this.isLoading = true
      this.axios.patch('/v1/super-admin/container/status/' + this.$route.params.id, this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.isEdit = false
          this.fetch()
          this.$buefy.snackbar.open({
            message: 'Status changed successfully',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    fetch () {
      this.axios.get('/v1/app/users/container/status/' + this.$route.params.id)
        .then(response => {
          this.statusList = response.data.status
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
    }
  }
}
</script>

<style scoped>

</style>
