<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <assign-driver
        slot="right"
        :driver="schedule != null ? schedule.driver : null"
      />
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="'Schedule Details'"
          icon="clock-outline"
          class="tile is-child"
        >
          <b-field label="Schedule Date">
            <b-input
              :value="schedule.date | formatDate(true)"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Status">
            <b-input
              :value="schedule.status"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Address">
            <b-input
              :value="schedule.address"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="City">
            <b-input
              :value="schedule.city"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Zip Code">
            <b-input
              :value="schedule.zip_code"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Description">
            <b-input
              :value="schedule.description"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>

        <card-component
          :title="'Packages'"
          icon="gift"
          class="tile is-child"
        >
          <b-collapse
            v-for="(pack, index) of schedule.packages"
            :key="index"
            class="card"
            animation="slide"
            :open="isOpen == index"
            :aria-id="'contentIdForA11y5-' + index"
            @open="isOpen = index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">
                  {{ pack.package_type }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'"
                  />
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-field label="Package Content">
                  <b-input
                    :value="pack.package_content"
                    custom-class="is-static"
                    readonly
                  />
                </b-field>
                <b-field label="Package Dimensions (L/W/H)">
                  <b-input
                    :value="pack.length + ' / ' + pack.width + ' / ' + pack.height"
                    custom-class="is-static"
                    readonly
                  />
                </b-field>
                <b-field label="Quantity">
                  <b-input
                    :value="pack.quantity"
                    custom-class="is-static"
                    readonly
                  />
                </b-field>
              </div>
            </div>
          </b-collapse>
        </card-component>
      </tiles>
      <tiles>
        <card-component
          :title="'Client Details'"
          icon="account-arrow-right"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="schedule.user.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="schedule.user.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="schedule.user.dial_code + ' ' + schedule.user.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          :title="'Recipient Details'"
          icon="account-arrow-left"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="schedule.receiver.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="schedule.receiver.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="schedule.receiver.dial_code + ' ' + schedule.receiver.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Delivery Type">
            <b-input
              :value="schedule.receiver.delivery_type"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
        <card-component
          v-if="schedule.driver != null"
          :title="'Driver Details'"
          icon="car"
          class="tile is-child"
        >
          <b-field label="Name">
            <b-input
              :value="schedule.driver.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Email">
            <b-input
              :value="schedule.driver.email"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Phone">
            <b-input
              :value="schedule.driver.dial_code + ' ' + schedule.driver.phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import AssignDriver from '@/components/dashboard/AssignDriver'
export default {
  name: 'ScheduleInformation',
  components: {
    TitleBar,
    HeroBar,
    Tiles,
    CardComponent,
    AssignDriver
  },
  data () {
    return {
      schedule: null,
      isOpen: null
    }
  },
  computed: {
    titleStack () {
      return [
        'Dashboard',
        'Schedules',
        this.schedule ? this.schedule.id : 'Schedule'
      ]
    },
    heroTitle () {
      return this.schedule ? this.schedule.id : 'Schedule'
    }
  },
  created () {
    this.getSchedule()
    this.$store.dispatch('fetchDrivers')
  },
  methods: {
    getSchedule () {
      this.axios.get('/v1/app/users/schedule/' + this.$route.params.id)
        .then(response => {
          this.schedule = response.data
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.label {
  color: #17561f !important;
}
</style>
