<template>
  <div v-if="tracking">
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          icon="menu"
          class="tile is-child"
        >
          <b-tabs v-model="activeTab">
            <b-tab-item label="Shipments">
              <b-table
                :checked-rows.sync="checkedRows"
                :checkable="false"
                :paginated="false"
                :data="tracking.shipments"
                default-sort="name"
                striped
                hoverable
              >
                <b-table-column
                  v-slot="props"
                  cell-class="has-no-head-mobile is-image-cell"
                >
                  <div class="image">
                    <img
                      :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.mode"
                      class="is-rounded"
                    >
                  </div>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Mode"
                  field="mode"
                  sortable
                >
                  {{ props.row.mode }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Tracking number"
                  field="tracking_number"
                  sortable
                >
                  {{ props.row.tracking_number }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Item(s) count"
                  field="items_count"
                  sortable
                >
                  {{ props.row.shipment_items.length }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Total($)"
                  field="total"
                  sortable
                >
                  {{ props.row.total_price }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Paid($)"
                  field="paid"
                  sortable
                >
                  {{ props.row.paid }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Balance($)"
                  field="balance"
                  sortable
                >
                  {{ calculateBalance(props.row.paid, props.row.total_price) }}
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Date"
                  field="date"
                  sortable
                >
                  <small style="color: blue">{{ props.row.created_at | formatDate(false) }}</small>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  label="Status"
                  field="status"
                  sortable
                >
                  <b-tag :type="props.row.latest_status.status === 'In Transit' ? 'is-info' : 'is-success'">
                    {{ props.row.latest_status.status }}
                  </b-tag>
                </b-table-column>
                <b-table-column
                  v-slot="props"
                  custom-key="actions"
                  cell-class="is-actions-cell"
                >
                  <div class="buttons is-right no-wrap">
                    <tracking-shipments-manage
                      :type="'remove'"
                      :shipment="props.row"
                      @refresh="fetch"
                    />
                    <router-link
                      :to="{name:'shipment', params: {id: props.row.tracking_number}}"
                      class="button is-small is-success"
                    >
                      <b-icon
                        icon="arrow-right-circle"
                        size="is-small"
                      />
                    </router-link>
                  </div>
                </b-table-column>
                <section
                  slot="empty"
                  class="section"
                >
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon
                        icon="emoticon-sad"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing's here&hellip;</p>
                  </div>
                </section>
              </b-table>
            </b-tab-item>

            <b-tab-item label="Add Shipment">
              <search-shipments @refresh="fetch" />
            </b-tab-item>
            <b-tab-item label="Tracking Status">
              <tracking-status-updates />
            </b-tab-item>
          </b-tabs>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles.vue'
import CardComponent from '@/components/CardComponent.vue'
import SearchShipments from '@/components/dashboard/tracking/SearchShipments'
import TrackingShipmentsManage from '@/components/dashboard/tracking/TrackingShipmentsManage'
import TrackingStatusUpdates from '@/components/dashboard/tracking/TrackingStatusUpdates'

export default {
  name: 'Tracking',
  components: {
    TrackingStatusUpdates,
    TrackingShipmentsManage,
    SearchShipments,
    TitleBar,
    HeroBar,
    Tiles,
    CardComponent
  },
  data () {
    return {
      tracking: null,
      error: null,
      activeTab: 0,
      checkedRows: []
    }
  },
  computed: {
    titleStack () {
      return [
        'Dashboard',
        'Trackings',
        this.tracking != null ? this.tracking.tracking_number : 'Tracking'
      ]
    },
    heroTitle () {
      return this.tracking != null ? this.tracking.tracking_number : 'Tracking'
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.axios.get('/v1/super-admin/containers/' + this.$route.params.id)
        .then(response => {
          this.tracking = response.data
        })
        .catch(error => {
          this.error = error
        })
    }
  }
}
</script>

<style scoped>

</style>
