<script>
export default {
  name: 'ManageShipmentItem',
  props: {
    package: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      isModalActive: false,
      packageTypes: ['Box', 'Barrel', 'Tote', 'Suitecase', 'Crate', 'Bag', 'Others'],
      form: {
        package_type: '',
        package_content: '',
        delivery_instructions: '',
        package_value: '',
        package_volume: '',
        length: '',
        width: '',
        height: '',
        final_price: '',
        weight: '',
        driver_comment: '',
        quantity: '',
        delivery_cost: '0.0'
      }
    }
  },
  watch: {
    package: {
      handler () {
        if (this.package != null) {
          this.form.package_type = this.package.package_type
          this.form.package_content = this.package.package_content
          this.form.delivery_instructions = this.package.delivery_instructions
          this.form.package_value = this.package.package_value
          this.form.quantity = this.package.quantity
          this.form.length = this.package.length
          this.form.weight = this.package.weight
          this.form.width = this.package.width
          this.form.height = this.package.height
          this.form.driver_comment = this.package.driver_comment
          this.form.final_price = this.package.final_price
        }
      },
      immediate: true
    }
  },
  methods: {
    submit () {
      this.form.package_volume = this.form.length * this.form.width * this.form.height
      this.isLoading = true
      this.axios.post(`/v1/app/shipment/update-item/${this.package.id}`, this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.form = {}
          this.$buefy.snackbar.open({
            message: 'Shipment item updated successfully',
            queue: false
          })
          this.$emit('refresh', 1)
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    }
  }
}
</script>

<template>
  <div>
    <b-button
      native-type="button"
      type="is-success"
      expanded
      @click="isModalActive = true"
    >
      Edit
    </b-button>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Edit Package
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            horizontal
            label="Package type"
            :type="{ 'is-danger': errors.package_type }"
            :message="{ [errors.package_type ? errors.package_type[0] : '']: errors.package_type }"
          >
            <b-select placeholder="Select package type" expanded v-model="form.package_type">
              <option
                v-for="option in packageTypes"
                :value="option"
                :key="option">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <b-field
            horizontal
            label="Package Content"
            :type="{ 'is-danger': errors.package_content }"
            :message="{ [errors.package_content ? errors.package_content[0] : '']: errors.package_content }"
          >
            <b-input
              v-model="form.package_content"
              name="package_content"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="Delivery Instructions"
            :type="{ 'is-danger': errors.delivery_instructions }"
            :message="{ [errors.delivery_instructions ? errors.delivery_instructions[0] : '']: errors.delivery_instructions }"
          >
            <b-input
              v-model="form.delivery_instructions"
              name="delivery_instructions"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="Package Value"
            :type="{ 'is-danger': errors.package_value }"
            :message="{ [errors.package_value ? errors.package_value[0] : '']: errors.package_value }"
          >
            <b-input
              v-model="form.package_value"
              name="package_value"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Package Length"
            :type="{ 'is-danger': errors.length }"
            :message="{ [errors.length ? errors.length[0] : '']: errors.length }"
          >
            <b-input
              v-model="form.length"
              name="package_length"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Package Width"
            :type="{ 'is-danger': errors.width }"
            :message="{ [errors.width ? errors.width[0] : '']: errors.width }"
          >
            <b-input
              v-model="form.width"
              name="package_width"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Package Height"
            :type="{ 'is-danger': errors.height }"
            :message="{ [errors.height ? errors.height[0] : '']: errors.height }"
          >
            <b-input
              v-model="form.height"
              name="package_height"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Package Weight (lb)"
            :type="{ 'is-danger': errors.weight }"
            :message="{ [errors.weight ? errors.weight[0] : '']: errors.weight }"
          >
            <b-input
              v-model="form.weight"
              name="package_weight"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Quantity"
            :type="{ 'is-danger': errors.quantity }"
            :message="{ [errors.quantity ? errors.quantity[0] : '']: errors.quantity }"
          >
            <b-input
              v-model="form.quantity"
              name="package_quantity"
              required
              type="number"
            />
          </b-field>
          <b-field
            horizontal
            label="Driver Comment"
            :type="{ 'is-danger': errors.driver_comment }"
            :message="{ [errors.driver_comment ? errors.driver_comment[0] : '']: errors.driver_comment }"
          >
            <b-input
              v-model="form.driver_comment"
              name="driver_comment"
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Submit
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
