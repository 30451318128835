<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-success"
        expanded
        @click="isModalActive = true"
      >
        Mark as not paid
      </b-button>
    </div>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Are you sure you want to mark this as not paid?
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Yes, Mark as not paid
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'NotPaid',
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.get('/v1/app/shipment/not-paid/' + this.shipment.id).then(() => {
        this.isLoading = false
        this.isModalActive = false
        this.$emit('refresh')
        this.$buefy.snackbar.open({
          message: 'Shipment marked as not paid',
          queue: false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
