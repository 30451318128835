<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-success"
        expanded
        @click="isModalActive = true"
      >
        Change shipment status
      </b-button>
    </div>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Change shipment status
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            label="Shipment status"
            horizontal
          >
            <b-select
              v-model="form.status"
              placeholder="Select an option"
              expanded
            >
              <option
                v-for="item in items"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Save
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      errors: {},
      form: {
        status: ''
      },
      items: [
        'In Transit',
        'Picked Up'
      ]
    }
  },
  watch: {
    shipment: {
      handler () {
        this.form.status = this.shipment.latest_status.status
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.patch('/v1/super-admin/status/' + this.shipment.id, this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.$emit('refresh')
          this.$buefy.snackbar.open({
            message: 'Shipment status updated successfully',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    }
  }
}
</script>

<style scoped>

</style>
