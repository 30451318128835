<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Tracking
      <manage-tracking
        slot="right"
        @refresh="fetch"
      />
    </hero-bar>
    <section class="section is-main-section">
      <div class="mb-2">
        <Search
          :search-placeholder="searchPlaceholder"
          @search="searchData"
        />
      </div>
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="tracking"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.tracking_number"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Tracking Number"
          field="name"
          sortable
        >
          {{ props.row.tracking_number }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Created At"
          field="created_at"
        >
          <small>{{ props.row.created_at | formatDate(false) }}</small>
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <div class="buttons is-right no-wrap">
            <manage-tracking
              :tracking="props.row"
              @refresh="fetch"
            />
            <router-link
              :to="{name:'tracking', params: {id: props.row.id, tracking_number: props.row.tracking_number}}"
              class="button is-small is-success"
            >
              <b-icon
                icon="arrow-right-circle"
                size="is-small"
              />
            </router-link>
          </div>
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
      <pagination
        :total="total"
        :per-page="perPage"
        :current-arg="currentArg"
        @page-change="pageChange"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Pagination from '@/components/dashboard/Pagination'
import Search from '@/components/dashboard/Search'
import ManageTracking from '@/components/dashboard/ManageTracking'

export default {
  name: 'Trackings',
  components: {
    ManageTracking,
    TitleBar,
    HeroBar,
    Pagination,
    Search
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Tracking'],
      tracking: [],
      checkedRows: [],
      total: null,
      perPage: null,
      currentArg: null,
      page: 1,
      searchPlaceholder: 'Search tracking number',
      search: ''
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    searchData (search) {
      this.search = search
      this.page = 1
      this.fetch()
    },
    fetch () {
      this.axios.get('/v1/super-admin/containers?page=' + this.page + '&search=' + this.search)
        .then(response => {
          this.tracking = response.data.data
          this.total = response.data.total
          this.perPage = response.data.per_page
          this.currentArg = response.data.current_page
        })
        .catch(error => {
          console.log(error)
        })
    },
    pageChange (page) {
      this.page = page
      this.fetch()
    }
  }
}
</script>

<style scoped>

</style>
