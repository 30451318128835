<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-danger"
        expanded
        @click="isModalActive = true"
      >
        Delete shipment
      </b-button>
    </div>

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Are you sure you want to delete this shipment?
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Yes, delete shipment
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DeleteShipment',
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.delete(`/v1/app/shipment/delete/${this.shipment.id}`)
        .then(() => {
          this.isModalActive = false
          this.$buefy.snackbar.open({
            message: 'Shipment deleted',
            queue: false
          })
          this.$router.push('/shipments')
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
