<template>
  <div>
    <b-field>
      <b-input
        v-model="search"
        v-debounce:400="onSearch"
        :placeholder="searchPlaceholder"
        type="search"
        icon="magnify"
        icon-clickable
      />
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    searchPlaceholder: {
      type: String,
      default: 'Search...'
    }
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    onSearch () {
      this.$emit('search', this.search)
    }
  }
}
</script>

<style scoped>

</style>
