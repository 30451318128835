<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Notifications
    </hero-bar>
    <section class="section is-main-section">
      <b-notification
        v-for="notification in notifications"
        :key="notification.id"
        :type="notification.is_read ? 'is-white' : 'is-success' + ' is-light'"
        icon="information-outline"
        size="24"
        has-icon
        role="alert"
        :closable="false"
      >
        <p><b class="has-text-success">{{ notification.title }}</b></p>
        <small class="has-text-info">{{ notification.created_at | formatDate(false) }}</small>
        <p><small style="color: #2e323a">{{ notification.message }}</small></p>
        <small v-if="notification.notifiable_type === 'App\\Models\\ShipmentSchedule'">
          <router-link
            class="is-clickable"
            :to="{name: 'schedule', params: {id: notification.notifiable_id}}"
          >
            Open schedule
          </router-link>
        </small>
        <small v-if="notification.notifiable_type === 'App\\Models\\Shipment'">
          <router-link
            class="is-clickable"
            :to="{name: 'shipment', params: {id: notification.notifiable_id}}"
          >
            Open shipment
          </router-link>
        </small>
        <small
          v-if="!notification.is_read"
          class="is-clickable"
          style="float: right; color: blue"
          @click="markAsRead(notification)"
        >[Mark as read]</small>
      </b-notification>
      <pagination
        :total="total"
        :per-page="perPage"
        :current-arg="currentArg"
        @page-change="pageChange"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Pagination from '@/components/dashboard/Pagination'

export default {
  name: 'Notifications',
  components: {
    TitleBar,
    HeroBar,
    Pagination
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Notifications'],
      notifications: [],
      checkedRows: [],
      total: null,
      perPage: null,
      currentArg: null,
      page: 1,
      error: null
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    markAsRead (notification) {
      this.axios.get('/v1/app/users/notifications/' + notification.id)
        .then(() => {
          this.notifications = this.notifications.map(n => {
            if (n.id === notification.id) {
              n.is_read = 1
            }
            return n
          })
          this.$buefy.snackbar.open({
            message: 'Notification marked as read',
            queue: false
          })
        })
        .catch(error => {
          this.error = error
        })
    },
    pageChange (page) {
      this.page = page
      this.fetch()
    },
    fetch () {
      this.axios.get('/v1/app/users/notifications?page=' + this.page)
        .then(response => {
          this.notifications = response.data.data
          this.total = response.data.total
          this.perPage = response.data.per_page
          this.currentArg = response.data.current_page
        })
        .catch(error => {
          this.notifications = []
          this.total = 0
          this.perPage = 0
          this.currentArg = null
          this.error = error
        })
    }
  }
}
</script>

<style scoped>

</style>
