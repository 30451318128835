<template>
  <div>
    <b-button
      v-if="type === 'remove'"
      size="is-small"
      type="is-danger"
      icon-right="close"
      @click="isModalActive = true"
    />

    <b-button
      v-if="type === 'add'"
      size="is-small"
      type="is-info"
      icon-right="plus-thick"
      @click="isModalActive = true"
    />

    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <span v-if="type === 'remove'">Are you sure you want to remove shipment from this tracking?</span>
            <span v-if="type === 'add'">Are you sure you want to add shipment to this tracking?</span>
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            Yes, <span>{{ type }}</span> shipment
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'TrackingShipmentsManage',
  props: {
    shipment: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false,
      isLoading: false,
      error: null
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.axios.patch('/v1/super-admin/container/add/' + this.shipment.id, {
        containerId: this.$route.params.id
      })
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.$emit('refresh')
          this.$buefy.snackbar.open({
            message: 'Shipment has been ' + this.type + 'ed',
            queue: false
          })
        })
        .catch(error => {
          this.isLoading = false
          this.isModalActive = false
          this.error = error
        })
    }
  }
}
</script>

<style scoped>

</style>
