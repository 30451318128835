<template>
  <div>
    <b-button
      v-if="state"
      size="is-small"
      type="is-success"
      icon-right="pencil"
      @click="isModalActive = true"
    />
    <b-button
      v-else
      native-type="button"
      type="is-success"
      @click="isModalActive = true; isEdit = false"
    >
      Add State
    </b-button>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ isEdit ? 'Edit State' : 'Add State' }}
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            horizontal
            label="Name"
            :type="{ 'is-danger': errors.name }"
            :message="{ [errors.name ? errors.name[0] : '']: errors.name }"
          >
            <b-input
              v-model="form.name"
              name="name"
              required
            />
          </b-field>
          <b-field
            horizontal
            label="Rate"
            :type="{ 'is-danger': errors.rate }"
            :message="{ [errors.rate ? errors.rate[0] : '']: errors.rate }"
          >
            <b-input
              v-model="form.rate"
              name="rate"
              type="number"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            v-if="isEdit"
            type="is-success"
            :loading="isLoading"
            @click="update"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
          <b-button
            v-else
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ManageStates',
  props: {
    state: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      form: {
        name: '',
        rate: ''
      },
      isModalActive: false,
      isEdit: false,
      errors: {},
      isLoading: false
    }
  },
  watch: {
    state: {
      handler () {
        if (this.state != null) {
          this.isEdit = true
          this.form = {
            name: this.state.name,
            rate: this.state.rate
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    update () {
      this.errors = {}
      this.isLoading = true
      this.axios.patch(`/v1/super-admin/states/${this.state.id}`, this.form)
        .then(() => {
          this.$emit('refresh')
          this.isModalActive = false
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: 'Update successfully',
            queue: false
          })
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.isLoading = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    submit () {
      this.errors = {}
      this.axios.post('/v1/super-admin/states', this.form)
        .then(() => {
          this.isModalActive = false
          this.form = {
            name: '',
            rate: ''
          }
          this.$emit('refresh')
          this.$buefy.snackbar.open({
            message: 'State created successfully',
            queue: false
          })
        })
        .catch(error => {
          this.errors = error.response.data.errors
        })
    }
  }
}
</script>

<style scoped>

</style>
