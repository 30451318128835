<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      States
      <ManageStates
        slot="right"
        @refresh="fetchStates"
      />
    </hero-bar>
    <section class="section is-main-section">
      <div class="mb-2">
        <Search
          :search-placeholder="searchPlaceholder"
          @search="searchData"
        />
      </div>
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="states"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.name"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable
        >
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Rate"
          field="rate"
          sortable
        >
          {{ props.row.rate }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <ManageStates
            :state="props.row"
            @refresh="fetchStates"
          />
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Search from '@/components/dashboard/Search'
import ManageStates from '@/components/dashboard/ManageStates'

export default {
  name: 'States',
  components: {
    TitleBar,
    HeroBar,
    Search,
    ManageStates
  },
  data () {
    return {
      titleStack: ['Dashboard', 'States'],
      states: [],
      checkedRows: [],
      searchPlaceholder: 'Search by name',
      search: ''
    }
  },
  created () {
    this.fetchStates()
  },
  methods: {
    searchData (search) {
      this.search = search
      this.fetchStates()
    },
    fetchStates () {
      this.axios.get('/v1/app/states?name=' + this.search)
        .then(response => {
          this.states = response.data.states
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
