<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Schedule
    </hero-bar>
    <section class="section is-main-section">
      <div class="mb-2">
        <b-field label="Select a date">
          <b-datepicker
            v-model="date"
            placeholder="Sort by date of schedule..."
            @input="onDateChange"
          />
        </b-field>
      </div>
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="schedule"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.user.name"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Client Name"
          field="name"
          sortable
        >
          <router-link :to="{name:'client.edit', params: {id: props.row.user.id}}">
            {{ props.row.user.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Status"
          field="status"
          sortable
        >
          <b-tag type="is-info">
            {{ props.row.status }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Schedule Date"
          field="schedule_date"
          sortable
        >
          {{ props.row.date | formatDate(true) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Created At"
          field="created_at"
        >
          {{ props.row.created_at | formatDate(false) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <div class="buttons is-right no-wrap">
            <router-link
              :to="{name:'schedule', params: {id: props.row.id}}"
              class="button is-small is-success"
            >
              <b-icon
                icon="arrow-right-circle"
                size="is-small"
              />
            </router-link>
          </div>
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
      <pagination
        :total="total"
        :per-page="perPage"
        :current-arg="currentArg"
        @page-change="pageChange"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Pagination from '@/components/dashboard/Pagination'
import moment from 'moment'

export default {
  name: 'Schedule',
  components: {
    TitleBar,
    HeroBar,
    Pagination
  },
  data () {
    return {
      titleStack: ['Dashboard', 'Schedule'],
      schedule: [],
      checkedRows: [],
      total: null,
      perPage: null,
      currentArg: null,
      page: 1,
      date: null,
      scheduledDate: '',
      moment: moment
    }
  },
  created () {
    this.getSchedule()
  },
  methods: {
    onDateChange (date) {
      this.scheduledDate = moment(date).format('YYYY-MM-DD')
      this.getSchedule()
    },
    pageChange (page) {
      this.page = page
      this.getSchedule()
    },
    getSchedule () {
      this.axios.get('/v1/app/users/schedule?page=' + this.page + '&scheduledDate=' + this.scheduledDate).then(response => {
        this.schedule = response.data.data
        this.total = response.data.total
        this.perPage = parseInt(response.data.per_page)
        this.currentArg = response.data.current_page
      })
    }
  }
}
</script>

<style scoped>

</style>
