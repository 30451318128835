<template>
  <div>
    <div class="mb-2">
      <Search
        :search-placeholder="searchPlaceholder"
        @search="searchData"
      />
      <b-table
        :checked-rows.sync="checkedRows"
        :checkable="false"
        :paginated="false"
        :data="shipments"
        default-sort="name"
        striped
        hoverable
      >
        <b-table-column
          v-slot="props"
          cell-class="has-no-head-mobile is-image-cell"
        >
          <div class="image">
            <img
              :src="'https://ui-avatars.com/api/?background=17561f&color=fafafa&name=' + props.row.mode"
              class="is-rounded"
            >
          </div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Mode"
          field="mode"
          sortable
        >
          {{ props.row.mode }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Tracking number"
          field="tracking_number"
          sortable
        >
          {{ props.row.tracking_number }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Item(s) count"
          field="items_count"
          sortable
        >
          {{ props.row.shipment_items.length }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Total($)"
          field="total"
          sortable
        >
          {{ props.row.total_price }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Paid($)"
          field="paid"
          sortable
        >
          {{ props.row.paid }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Balance($)"
          field="balance"
          sortable
        >
          {{ calculateBalance(props.row.paid, props.row.total_price) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Date"
          field="date"
          sortable
        >
          <small style="color: blue">{{ props.row.created_at | formatDate(false) }}</small>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Status"
          field="status"
          sortable
        >
          <b-tag :type="props.row.latest_status.status === 'In Transit' ? 'is-info' : 'is-success'">
            {{ props.row.latest_status.status }}
          </b-tag>
        </b-table-column>
        <b-table-column
          v-slot="props"
          custom-key="actions"
          cell-class="is-actions-cell"
        >
          <div class="buttons is-right no-wrap">
            <tracking-shipments-manage
              :type="'add'"
              :shipment="props.row"
              @refresh="refresh"
            />
            <router-link
              :to="{name:'shipment', params: {id: props.row.tracking_number}}"
              class="button is-small is-success"
            >
              <b-icon
                icon="arrow-right-circle"
                size="is-small"
              />
            </router-link>
          </div>
        </b-table-column>
        <section
          slot="empty"
          class="section"
        >
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </div>
        </section>
      </b-table>
    </div>
  </div>
</template>

<script>
import Search from '@/components/dashboard/Search'
import TrackingShipmentsManage from '@/components/dashboard/tracking/TrackingShipmentsManage'
export default {
  name: 'SearchShipments',
  components: {
    Search,
    TrackingShipmentsManage
  },
  data () {
    return {
      searchPlaceholder: 'Search by tracking number',
      search: '',
      shipments: [],
      checkedRows: []
    }
  },
  methods: {
    searchData (search) {
      this.search = search
      this.fetch()
    },
    fetch () {
      this.axios.get('/v1/app/shipment?tId=' + this.search)
        .then(response => {
          const shipments = response.data.shipments
          this.shipments = shipments.filter(shipment => {
            return shipment.shipment_container_id === null
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    refresh () {
      this.search = ''
      this.shipments = []
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
