<template>
  <div>
    <b-button
      v-if="tracking"
      size="is-small"
      type="is-success"
      icon-right="pencil"
      @click="isModalActive = true"
    />
    <b-button
      v-else
      native-type="button"
      type="is-success"
      @click="isModalActive = true; isEdit = false"
    >
      Add Tracking
    </b-button>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ isEdit ? 'Edit Tracking' : 'Add Tracking' }}
          </p>
          <button
            type="button"
            class="delete"
            @click="isModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <b-field
            horizontal
            label="Tracking Number"
            :type="{ 'is-danger': errors.tracking_number }"
            :message="{ [errors.tracking_number ? errors.tracking_number[0] : '']: errors.tracking_number }"
          >
            <b-input
              v-model="form.tracking_number"
              name="tracking_number"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="isModalActive = false"
          >
            Cancel
          </b-button>
          <b-button
            v-if="isEdit"
            type="is-success"
            :loading="isLoading"
            @click="update"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
          <b-button
            v-else
            type="is-success"
            :loading="isLoading"
            @click="submit"
          >
            {{ isEdit ? 'Update' : 'Create' }}
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ManageTracking',
  props: {
    tracking: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      form: {
        tracking_number: ''
      },
      isModalActive: false,
      isEdit: false,
      errors: {},
      isLoading: false
    }
  },
  watch: {
    tracking: {
      handler () {
        if (this.tracking != null) {
          this.isEdit = true
          this.form = this.tracking
        }
      },
      immediate: true
    }
  },
  methods: {
    update () {
      this.isLoading = true
      this.errors = {}
      this.axios.patch('/v1/super-admin/containers/' + this.tracking.id, this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.$emit('refresh')
          this.$buefy.snackbar.open({
            message: 'Tracking updated successfully',
            queue: false
          })
          this.clearForm()
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    submit () {
      this.isLoading = true
      this.errors = {}
      this.axios.post('/v1/super-admin/containers', this.form)
        .then(() => {
          this.isLoading = false
          this.isModalActive = false
          this.$emit('refresh')
          this.$buefy.snackbar.open({
            message: 'Tracking added successfully',
            queue: false
          })
          this.clearForm()
        })
        .catch(error => {
          this.isLoading = false
          this.errors = error.response.data.errors
        })
    },
    clearForm () {
      this.form = {
        tracking_number: ''
      }
      this.errors = {}
    }
  }
}
</script>

<style scoped>

</style>
